import { Link } from 'react-router-dom';

import './thanks.scss';

const ThanksYou = () => {
  return (
    <div className="container">
      <ThankYouIcon />

      <h1>Thank You!</h1>
      <h2>We’ll respond to your request shortly!</h2>
      <p>Looking forward to serving you!</p>

      <Link to="/">Back to Home Page</Link>
    </div>
  );
};

export default ThanksYou;

const ThankYouIcon = () => (
  <div className="backg">
    <div className="planet">
      <div className="r1"></div>
      <div className="r2"></div>
      <div className="r3"></div>
      <div className="r4"></div>
      <div className="r5"></div>
      <div className="r6"></div>
      <div className="r7"></div>
      <div className="r8"></div>
      <div className="shad"></div>
    </div>
    <div className="stars">
      <div className="s1"></div>
      <div className="s2"></div>
      <div className="s3"></div>
      <div className="s4"></div>
      <div className="s5"></div>
      <div className="s6"></div>
    </div>
    <div className="an">
      <div className="tank"></div>
      <div className="astro">
        <div className="helmet">
          <div className="glass">
            <div className="shine"></div>
          </div>
        </div>
        <div className="dress">
          <div className="c">
            <div className="btn1"></div>
            <div className="btn2"></div>
            <div className="btn3"></div>
            <div className="btn4"></div>
          </div>
        </div>
        <div className="handl">
          <div className="handl1">
            <div className="glovel">
              <div className="thumbl"></div>
              <div className="b2"></div>
            </div>
          </div>
        </div>
        <div className="handr">
          <div className="handr1">
            <div className="glover">
              <div className="thumbr"></div>
              <div className="b1"></div>
            </div>
          </div>
        </div>
        <div className="legl">
          <div className="bootl1">
            <div className="bootl2"></div>
          </div>
        </div>
        <div className="legr">
          <div className="bootr1">
            <div className="bootr2"></div>
          </div>
        </div>
        <div className="pipe">
          <div className="pipe2">
            <div className="pipe3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
