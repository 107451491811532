import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ThanksYou } from '../components';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Thanks = () => {
  // const navigate = useNavigate();
  // const query = useQuery();

  // const city = query.get('city');

  // useEffect(() => {
  //   if (!city) navigate('/');
  //   console.log(city);
  // }, [city, navigate]);

  return (
    <section className="thank-you-block">
      <ThanksYou />
    </section>
  );
};

export default Thanks;
