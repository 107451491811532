import './footer.scss';

const Footer = () => (
  <footer className="page-footer">
    <a href="https://toporin.com" target="_blank" rel="noReferrer">
      Designed by <strong>Toporin Studio®</strong> in California
    </a>
  </footer>
);

export default Footer;
