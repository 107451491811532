import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Header, Footer } from '../';

import './layout.scss';

const Layout = ({ id, step, phone, children }) => {
  const location = window.location.pathname.split('/')[1];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [step]);

  return (
    <div className="layout" id={id}>
      {location !== '404' && <Header phone={phone} />}
      <main>{children}</main>
      {location !== '404' && <Footer />}
      <ToastContainer />
    </div>
  );
};

export default Layout;
